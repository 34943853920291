import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);


const router = new VueRouter({
    mode: 'history',
});

/**
 * @brief Router guard.
 */
router.beforeEach((to, from, next) => {
    if (to.path === "/") {
        // next()
    }

    if (Object.prototype.hasOwnProperty.call(to.query, "referrer")) {
        let referrer = to.query.referrer;

        // When user comes from GRIP based host, the user won't have to choose GRIP
        if (referrer.includes("grip") || referrer.includes("kpn")) {
            window.location.replace(config.SUITE_API_GRIP_URL + "auth/?oauth_provider_id=" + config.GRIP_PROVIDER_UUID);
        } else if (referrer.includes("strongpin")) {
            window.location.replace(config.SUITE_API_BASE_URL + "auth/?oauth_provider_id=" + config.SPIN_PROVIDER_UUID);
        }
    }

    next()

});

export default router;
